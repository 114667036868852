import React from "react";
import Header from './components/header'
import Footer from './components/footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from "./pages/home"
import About from "./pages/about"
import Product from "./pages/products"
import Contact from './pages/contact'
import Learn from './pages/learn'
import './App.css'
import Services from "./pages/services.js";
import ScrollButton from "./components/ScrollButton";


function App() {
  return (
    <Router >
        <div className='relative z-10'>
          <Header />
        </div>
          <ScrollButton />
            <Route exact path='/' component={Home} />
                <Switch>
                  <Route path='/about' component={About} />
                  <Route path='/products' component={Product} />
                  <Route path='/learn' component={Learn} />
                  <Route path='/contact' component={Contact} />
                  <Route path='/services' component={Services} />
                </Switch>

        <Footer />
     </Router>
  );
}

export default App;
