import React, { Fragment } from 'react'
import 'antd/dist/antd.css';
import BI from '../../assets/images/webicons06.png'
import DM from '../../assets/images/webicons05.png'
import CS from '../../assets/images/webicons04.png'
import TC from '../../assets/images/webicons03.png'
import BG from '../../assets/images/webicons10.png'
import BGG from '../../assets/images/webicons11.png'
import Loose from '../../assets/images/webicons07.png'
import CustomArrows from '../../components/carousel'
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Link } from 'react-router-dom';


const Content = () => {
    return (
        <div className='h-25 mt-0 mx-auto w-11/12 text-xs lg:text-xs bg-glass text-left text-gray-400 font-all p-4 rounded-lg'>

            "Microsoft PowerBI Data Analytics Implementation by Datazen was a new and exciting way to anaylze our Operations or LOB (Line of Business) data for both real-time and historical analytics – on  PCs or mobile devices. Our top Executives use it every day to make key decisions."

                <div className='mt-2 text-left'>
                    <h3 className='text-dataorange text-xs font-oxy font-bold not-italic'>-Timpa Angaye ,<span>Head,ICT at ASCA</span></h3>
                </div>
        
            </div>
    )
}

const Sliders = () => {
        const settings = {
            infinite: true,
            slidesToShow: 3,
            arrows:false,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };

        
    
    return(
        <div className='pb-2 w-full px-4 lg:px-0'>
            <Slider {...settings}>
                    <Content />

                    <Content />

                    <Content />

                    <Content />

                    <Content />
            </Slider>
        </div>

    )
}


const Home = () => {

          
    return (
        <Fragment>
            <div className='bg-datadark'>
                    <CustomArrows />
                <div className=''>
                    <Sliders />
                </div>
                </div>

                <div className='mybg'>
                   
                    <div className='px-8 lg:px-0 pattern pb-20'>
                                       
                    <div className='pt-20 flex flex-col-reverse md:grid md:grid-cols-2 lg:gap-20 md:flex-row w-full lg:w-4/6 mx-auto'>
                        
                        <div className='h-auto bg-blue-50 p-6 my-12 flex rounded-xl lg:my-0'>
                            <img src={BG} alt='' className='my-auto' loading='lazy' />
                        </div>
                        
                        <div className='md:p-8 text-justify text-datadark my-auto'> 

                            <h1 className='font-bold text-xl lg:text-3xl font-oxy'>
                                Data Analytics
                            </h1>
                            <p className='font-ball text-md lg:text-base leading-loose'>
                                    We are aware that the important metrics you need may be buried under multiple data sources. Our job is to model your data unique to your Operations and draw valuable conclusions that give the
                                competitive advantages you desire.

                                Let us help you find trends and optimize your business strategies.
                            </p>
                        </div>
                        
                       
                    </div>


                    



                    <div className='mt-12 lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-1 lg:gap-20 md:flex-row w-full lg:w-4/6 mx-auto'>

                    <div className='md:p-8 text-justify text-datadark my-auto'> 
                            <h1 className='font-bold text-xl lg:text-3xl font-oxy'>
                                Cloud Technologies
                            </h1>
                            <p className='font-all text-md lg:text-base leading-loose'>
                                At Datazen, we are passionate out digital transformation -helping organizations stay revelant, competitive and with the right tools to succeed. This transformation ensures your business is fueled by data-based insights, collaborations nd increased productivity that saves cost
                            </p>
                      </div>

                        <div className='bg-blue-50 p-6 my-12 flex rounded-xl lg:my-0'>
                            <img src={BGG} alt='' className='my-auto' />
                        </div>

                    </div>
                </div>

                <div className='px-8 lg:px-0 py-12 lg:pt-16 lg:pb-24 bg-datadark'>


                    <h1 className='mt-0 text-center text-gray-400 text-2xl font-bold font-ball'>
                        Our Core Services
                    </h1>
                    
                    <div className='mt-12 w-full lg:w-4/6 mx-auto sm:grid sm:grid-cols-2 sm:gap-8'>
                            <div className='my-8 sm:my-0 bg-blue-50 hover:bg-blue-100 hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-16 rounded-xl'>
                                    <img src={BI} alt='' className='' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Business Intelligence/AI
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    Power your actions by turning information/data into insight. With our Big Data and Predictive Analytics solutions. – we fuel your business processes with actionable analytics.
                                </p>
                            </div>

                            <div className='my-8 sm:my-0 bg-blue-50  hover:bg-blue-100 hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-16 rounded-xl'>
                                    <img src={DM} alt='' className='' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Data Management
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    To succeed, organization need to better manage their data. It’s no longer enough just to manage your data – you need to unleash its potential leveraging technology.
                                </p>
                            </div>

                            <div className='my-8 sm:my-0 bg-blue-50 hover:bg-blue-100  hover:border-gray-300 rounded-2xl p-8 font-all'>
                                 <div className='w-16 rounded-xl'>
                                    <img src={CS} alt='' className='' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>Cloud Solutions</h1>
                                <p className='mt-8 text-md lg:text-base'>Business solutions are no longer clunky softwares. The future is digital for Corporate & Government organizations; we provide solutions to address a variety of business needs - CRM, ERP. Custom applications that spur productivity.</p>
                            </div>

                            <div className='my-8 sm:my-0 bg-blue-50 hover:bg-blue-100  hover:border-gray-300 rounded-2xl p-8 font-all'>
                            <div className='w-16 rounded-xl'>
                                    <img src={TC} alt='' className='' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Training & Consulting
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    We provide immersive training and 24/7 support to ensure increased availability and high performance of all your IT operations. We also offers practical hands-on trainings for analysts, aspiring analysts and any one that uses data.
                                </p>
                            </div>
                          
                    </div>


                   

                </div>
                
                
                <div className='px-8 lg:px-0 pattern py-16'>


                    <div className='mt-0 w-full lg:w-5/6 rounded-lg mx-auto p-8 grid grid-cols-1 md:grid-cols-2 gap-8'>
                        
                        <div className=''>
                            <img src={Loose} alt='' />
                        </div>

                        <div className='my-auto text-center md:text-left'>
                                <h1 className='mt-4 lg:mt-8 text-2xl lg:text-4xl font-ball font-bold text-datadark'>
                                    Bringing Data Insights to Everyone
                                </h1>
                                <p className='mt-2 lg:mt-0 text-sm lg:text-lg font-bold font-all text-datadark'>
                                    We Put Interactive, Easy-to-Use BI Tools In The Hands Of Those Who Need Them 
                                </p>
                                <Link to='/products' target='_top'>
                                    <button className='mt-4 lg:mt-8 px-3 py-2 text-datadark rounded-md hover:text-white bg-dataorange font-all'>
                                    Read More
                                    </button>
                                </Link>
                        </div>
                        
                    </div>
                
                </div>

                </div>
            
        </Fragment>
    )
}

export default Home
