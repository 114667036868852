import React,{ Fragment } from 'react'
import DM from '../../assets/images/webicons05.png'
import CS from '../../assets/images/webicons04.png'
import TC from '../../assets/images/webicons03.png'
import { FaArrowRight, FaCircle } from 'react-icons/fa'
import Image from '../../assets/images/webicons12.png'

const Services = () => {
    return (
        <Fragment>
            <div className='w-full bg-datadark'>
                    <div className='h-height flex flex-col w-full lg:w-11/12 items-center mx-auto my-auto'>
                    
                        <div className='h-full pl-12 lg:pl-24 flex lg:flex-row flex-col justify-between my-auto'>
                            <div className='mt-16 xl:my-auto h-auto w-11/12 lg:w-1/2 mr-8'>
                                    <h1 className='uppercase text-lg lg:text-2xl text-datalight font-bold font-oxy'>SERVICES<span className='text-dataorange'></span></h1>
                                    <div className='mt-4 lg:mt-6 font-all text-md text-left text-datalight'>     
                                    Data is the fuel of any successful organization. We work with varying BI / data analytics technologies such as Microsoft Azure ML (Machine Learning), Tableau, Microsoft PowerBI, Python & R programming etc. to analyze data – letting you support processes with the right analytics.
                                    
                                    </div>
                                    <div className='mt-8 lg:mt-12'>
                                        <a href='#read' className='px-3 py-2 text-white rounded-md hover:text-white bg-dataorange font-all'>Read More</a>
                                    </div>

                            </div>

                            <div className='block lg:w-1/2 h-auto flex justify-center opacity-70'>
                                    <img src={Image} loading='lazy' alt='' className='my-auto w-10/12' />
                            </div>

                        </div>

                        </div>
                    </div>

                    <div className='pattern py-4'>
                   
                    <div className='w-11/12 lg:w-4/6 mx-auto'>


                         
                    <div id='read' className='mt-20 w-full mx-auto flex flex-col sm:grid sm:grid-cols-3 sm:gap-8'>

                            <a href='#tag' className='my-8 sm:my-0 bg-blue-50 text-datadark group hover:text-blue-50 hover:bg-datadark hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-20 rounded-xl p-2 group-hover:bg-blue-50'>
                                    <img src={DM} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl group-hover:text-blue-50 font-bold mt-8'>
                                    Data Management
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    To succeed, organization need to better manage their data. It’s no longer enough just to manage your data – you need to unleash its potential leveraging technology.
                                </p>
                            </a>

                            <a href='#cloud' className='my-8 sm:my-0 bg-blue-50 text-datadark group hover:text-blue-50 hover:bg-datadark hover:border-gray-300 rounded-2xl p-8 font-all'>
                                 <div className='w-20 rounded-xl p-2 group-hover:bg-blue-50'>
                                    <img src={CS} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl group-hover:text-blue-50 font-bold mt-8'>Cloud Solutions</h1>
                                <p className='mt-8 text-md lg:text-base'>Business solutions are no longer clunky softwares. The future is digital for Corporate & Government organizations; we provide solutions to address a variety of business needs - CRM, ERP. Custom applications that spur productivity.</p>
                            </a>

                            <a href='#train' className='my-8 sm:my-0 bg-blue-50 text-datadark group hover:text-blue-50 hover:bg-datadark hover:border-gray-300 rounded-2xl p-8 font-all'>
                            <div className='w-20 rounded-xl p-2 group-hover:bg-blue-50'>
                                    <img src={TC} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl group-hover:text-blue-50 font-bold mt-8'>
                                    Training & Consulting
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    We provide immersive training and 24/7 support to ensure increased availability and high performance of all your IT operations. We also offers practical hands-on trainings for analysts, aspiring analysts and any one that uses data.
                                </p>
                            </a>
                          
                    </div>



                  

                    
                    <div id='tag' className='mt-12 lg:mt-20 w-full text-xl lg:text-3xl font-ball font-bold text-center lg:w-4/6 mx-auto'>
                        Our Data Management Services
                    </div>


                    <div className='my-8 text-left lg:text-center font-all  text-md lg:text-base'>
                            Our Data warehousing solution supports online analytical processing (OLAP), which enables high-level end users to gain insight into business operations through interactive and iterative access to the stored data. This enables business executives to improve corporate strategies and operational decision making by querying the data warehouse to examine business processes, performance and trends. If you are congregating data from multiple sources to a centralized point for powerful analytics and fact finding – our data warehousing solutions provide the best support for your project.
                    </div>


                       
                    <div className='h-auto mt-12 flex flex-col lg:grid lg:grid-cols-3 lg:gap-8 mx-auto'>

                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                    Enterprise Data Warehouse (EDW)
                                </h1>
                                <p className='font-ball  text-md lg:text-base leading-normal'>
                                    Power your actions by turning information/data into insight. With our Big Data and EDW solutions. – we fuel your business processes with actionable analytics.
                                </p>
                            </div>


                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                    Machine Learning (AI)
                                </h1>
                                <p className='font-ball  text-md lg:text-base leading-normal'>
                                Your ability to use machine learning models – to support revenue generation, cost saving, forecasting, classification etc. Predictive models that exploit patterns found in historical and transactional data to identify future outcomes, risks and opportunities.
                                </p>
                            </div>

                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                        Data Modelling
                                </h1>
                                <p className='font-ball  text-md lg:text-base leading-normal'>
                                        This is a representation of the data structures in a table for a company's database and is a very powerful expression of the company's business requirements.
                                </p>
                            </div>

                    </div>



                    <div id='cloud' className='mt-12 lg:mt-20 w-full text-xl lg:text-3xl font-ball font-bold text-center lg:w-4/6 mx-auto'>
                        Our Cloud Solutions
                    </div>


                    <div className='my-8 text-left lg:text-center font-all  text-md lg:text-base leading-loose'>
                    Our cutting-edge software team have built bespoke solutions to improve business efficiency and insights – leading to increase of profit for our clients. Whatever your business needs are, you're certain to find the perfect and innovative solution.
                    </div>


                       
                    <div className='h-auto mt-12 flex flex-col lg:grid lg:grid-cols-3 lg:gap-8 mx-auto'>

                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                    Software Licenses
                                </h1>
                                <p className='font-ball text-md lg:text-base'>
                                Access to discounted offers for Microsoft Productivity licenses (Office365 , Project, Teams etc.) and Cloud Computing infrustructure - Azure, and AWS.

Our OEM partnerships & advisory services is there to support you in workload planning, project design and implementation - delivering real values to your IT infrastructure.
                                </p>
                            </div>


                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                Business Apps
                                </h1>
                                <p className='font-ball text-md lg:text-base'>
                                Business solutions are no longer clunky softwares.


The future is digital for Corporate and Government organizations; we provide solutions to address a variety of business needs - CRM, as well custom Business Process automation applications that spur productivity.

Power Apps & Flows, SharePoint, Nintex etc.; are some of the tools we expertly deploy.
                                </p>
                            </div>

                            <div className='my-8 h-full lg:min-h-72 p-8 bg-blue-50 text-datadark rounded-xl'> 

                                <h1 className='font-bold text-md lg:text-lg font-oxy'>
                                    Custom Design & Build
                                </h1>
                                <p className='font-ball text-md lg:text-base'>
                                        
                                While this business varies dramatically from operation to operation, many companies share a common challenge - innovating from growth and success. We offers custom apps & solutions with superior visualizations, that are tailored specifically to address your needs. This gives you control over your operations and customer engagements.
 
 Let us deploy your Apps while you focus on what matters most!
                                </p>
                            </div>

                    </div>



                   

                    <div id='train' className='mt-32 lg:mt-20 w-full text-xl lg:text-3xl font-ball font-bold text-center lg:w-4/6 mx-auto'>
                             Welcome to BI Training
                    </div>

                    <div className='my-8 text-left lg:text-center font-all text-md lg:text-base'>
                                Learn Practical BI Courses Online, Complete Assignments, Earn Verifiable Digital Certificates.
                    </div>


                    <div className='my-20 flex flex-col md:grid md:grid-cols-3 lg:gap-8 md:flex-row w-full mx-auto'>
            
                           
                            <div className='p-8 border-l-2 border-gray-100 text-datadark'> 

                                <h1 className='font-bold text-md text-datadark lg:text-lg font-oxy'>
                                        PowerBI Dashboard in A Day (DIAD) Course
                                </h1>
                                <p className='font-all text-md lg:text-base leading-normal'>
                                Our DIAD program is a one-day introductory-to-intermediate course designed to accelerate your Power BI experience with practical, hands-on training. Our DIAD workshop include assets—presenter decks, demo scripts, step-by-step student hands-on lab and datasets, to ensure a successful event
                                <br></br>
                                    Register now to attend one of these events.
                                <br></br>
                                    <span className='font-bold'>Course Outline:</span>  
                                </p>
                                <div className='font-all text-sm'>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle  size={5}  className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Introduction to PowerBI</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle  size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Adding and Shaping Data</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5}  className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Report Design and Creation – PowerBI Desktop</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle  size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Publishing Reports and Creating Dashboards</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle  size={5}  className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Sharing and Collaboration – PowerBI Service</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Hands-on Exercise</p>
                                    </span>                                        
                                </div>
                                <div className='mt-8 lg:mt-4'>
                                        <button className='px-4 py-3 flex h-auto font-bold my-auto flex-row text-dataorange rounded-lg hover:text-datazen hover:bg-dataorange font-all'>Subscribe
                                            <FaArrowRight className='my-auto ml-2'/>
                                        </button>
                                    </div>
                            </div>
                        
                       

                            <div className='p-8 border-l-2 border-gray-100 text-datadark'> 

                                <h1 className='font-bold text-md text-datadark lg:text-lg font-oxy'>
                                        Data Analytics Essential Course
                                </h1>
                                <p className='font-all text-md lg:text-base leading-normal'>
                                    This is a practical hands-on training for your internal analysts, and any one that uses data. Our Training courses equip participants with time saving skills and techniques to go “from data to actionable insights” in the shortest possible time, towards achieving the organization’s objectives.
                                    We deliver this as a tailored classroom course for organization who want to empower their internal staffs with analytics skills.
                                <br></br>
                                    <span className='font-bold'>Course Outline:</span> 
                                </p>
                                <div className='font-all text-sm'>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Introduction to Business Intelligence</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Understanding Data Modeling</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Introduction to DAX</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>Visualizing Reports & Dashboard in Power BI</p>
                                    </span>
                                    <span className='flex flex-row h-auto'>
                                        <FaCircle size={5} className='my-auto text-dataorange'/> <p className='my-auto ml-4'>
                                    Data Preparation Techniques & Enterprise Management
                                        </p>
                                    </span>
                                                                        
                                </div>
                                <div className='mt-8 lg:mt-4'>
                                        <button className='px-4 py-3 flex h-auto font-bold my-auto flex-row text-dataorange rounded-lg hover:text-datazen hover:bg-dataorange font-all'>Enroll now
                                            <FaArrowRight className='my-auto ml-2'/>
                                        </button>
                                    </div>
                            </div>



                                <div className='p-8 border-l-2 border-gray-100 text-datadark'> 

                                    <h1 className='font-bold text-md text-datadark lg:text-lg font-oxy'>
                                            Zen Analytics Bootcamp
                                    </h1>
                                    <p className='font-all text-md lg:text-base leading-normal'>
                                    We deliver regular Data Analytics bootcamp to upskill aspiring analyst and BI architects. Get started quickly with a solid foundation on Analyzing and Visualizing Data with Power BI. This course contains all lessons on Introduction to Power BI Course plus lessons on Data Modeling, DAX, Data Preparation Techniques and Assignments.
                                    <br></br>
                                        Register now to attend one of these events.
                                    <br></br>
                                    </p>
                                    <div className='mt-8 lg:mt-0'>
                                        <button className='px-3 py-3 font-bold flex h-auto my-auto flex-row text-dataorange rounded-md hover:text-datazen hover:bg-dataorange font-all'>Subscribe
                                            <FaArrowRight className='my-auto ml-2'/>
                                        </button>
                                    </div>
                            </div>
                            
                        
                       
                    </div>
                    </div>
                    
                    </div>
        </Fragment>
    )
}

export default Services
