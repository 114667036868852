import React,{ Fragment,useState } from 'react'
import Video from '../../assets/video/enterprise-video.mp4'
import BiOne from '../../assets/images/bi-one.png'
import BiTwo from '../../assets/images/bi-two.png'
import BiThree from '../../assets/images/bi-three.png'
import { FaChartArea } from 'react-icons/fa'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
 


const Product = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>  
                    <iframe className ='iframe' src="https://app.powerbi.com/view?r=eyJrIjoiNzVjYTVjZWQtMjE0NS00NmZkLTliYzEtZWRhMGQwYzgxYzExIiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9&pageName=ReportSection6cc12f9f2a6e5dd049ec%22" title='sales' frameBorder={0} allowFullScreen={true} loading='lazy'></iframe>
              
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>  
                <iframe className="iframe" src="https://app.powerbi.com/view?r=eyJrIjoiYjUzM2Y3M2YtZTFkOC00NGVkLWI1OWQtNjEwM2Y3MjU5YzcxIiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9" frameBorder="0" title='last' allowFullScreen="true"></iframe>   
                </Box>
            </Modal>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>  
                    

                    <iframe className='iframe' src="https://app.powerbi.com/view?r=eyJrIjoiMzc5ZDU4ZTYtNDE2Mi00YzBiLWE0OTEtMDhjN2M3OGYzZWU3IiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9" title='logic' frameBorder="0" allowFullScreen={true}></iframe>
                </Box>
            </Modal>
        <Fragment>
            
            <div className='h-12 bg-datadark w-full'>

            </div>
                <video autoPlay muted loop id="myVideo">
                    <source src={Video} type="video/mp4"></source>
                </video>


            <div className='px-4 lg:px-0 py-8 lg:py-12 pattern'>

                    <h1 className='uppercase text-center text-lg lg:text-2xl text-datadark font-bold font-oxy'>ZEN ANALYTICS</h1>

            <div>


                <div className='mt-12 w-full text-md lg:text-base leading-loose font-all text-center lg:w-4/6 mx-auto'>


                Seperate and Innovate your organization from the crowd who employ no data when making decisions  - which inevitably lead to growth issues.<br></br> Instead, use the right insights to make more informed decisions. At Datazen, we create bespoke Data Analytic solutions which provides business intelligence to decision makers - giving them a bird eye view of their Operations & Customer Trends.


<br></br>


                </div>
                
                <div className='text-center font-all mt-12 text-md lg:text-base'>
                
                    Extraordinary business success is achievable with intelligent data

                </div>
                
                <div className='text-center font-all mt-4 text-md lg:text-base'>

                Explore some of our AI-modelled BI Apps:

                </div>
                 <div className='mt-20 w-full lg:w-5/6 mx-auto lg:grid lg:grid-cols-3 lg:gap-8'>

                     <div className='mt-8 lg:mt-0'>
                        
                            <div className='hidden lg:block h-auto my-8 lg:my-0 bg-glass-dark p-2 hover:border-gray-300 rounded-lg font-all'>
                               <img src={BiTwo} alt='' />
                            </div>

                            <div className='block lg:hidden mx-auto'>
                            <iframe className='iframe' src="https://app.powerbi.com/view?r=eyJrIjoiNzVjYTVjZWQtMjE0NS00NmZkLTliYzEtZWRhMGQwYzgxYzExIiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9&pageName=ReportSection6cc12f9f2a6e5dd049ec%22" title='sales' frameBorder="0" allowFullScreen={true}></iframe>
                            </div>

                            <h1 className='mt-4 text-center text-lg font-all font-bold'>Sales Insights App</h1>

                            <p className='font-all text-center text-md lg:text-base leading-normal'>Sales analytics solution that showcase sales performance in real time and optimize dollar spend. Our Sales BI app deliver powerful insights and in-depth revenue analysis which can handle mulitple data sources - CRM, Excel, SAP etc. and can be deployed for any industry. (FSI, Retail, CPG and Utilities)</p>

                            <div onClick={handleOpen} className='mt-12 w-1/3 mx-auto font-all rounded-md text-dataorange hover:bg-dataorange hover:text-white p-2 flex flex-row justify-center items-center cursor-pointer'>
                                <FaChartArea />
                                <button className='ml-2'>View</button>
                            </div>
                    
                    </div>

                    <div className='mt-8 lg:mt-0'>

                            <div className='hidden lg:block my-8 lg:my-0 bg-glass-dark p-2 hover:border-gray-300 rounded-lg font-all'>
                               <img src={BiOne} alt='' />
                            </div>

                            <div className='block lg:hidden mx-auto'>
                                <iframe className='iframe' src='https://app.powerbi.com/view?r=eyJrIjoiMzc5ZDU4ZTYtNDE2Mi00YzBiLWE0OTEtMDhjN2M3OGYzZWU3IiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9' title='logic' frameBorder="0" allowFullScreen={true}>
                                </iframe>
                            </div>

                            <h1 className='mt-4 text-center text-lg font-all font-bold'>Inventory Management App</h1>

                            <p className='font-all text-center text-md lg:text-base leading-loose'>Are you an innovation-driven Manufacturing organization? Get real time insights into the inventory on-hand data captured into your LOB systems. We optimize Operational cost  by providing powerful insights on inventory assessment and stock return analysis and re-order trends.</p>
                            
                            <div onClick={handleOpen2} className='mt-12 w-1/3 mx-auto font-all rounded-md text-dataorange hover:bg-dataorange hover:text-white p-2 flex flex-row justify-center items-center cursor-pointer'>
                                <FaChartArea />
                                <button className='ml-2'>View</button>
                            </div>
                            
                    </div>

                    <div className='mt-8 lg:mt-0'>

                            <div className='hidden lg:block my-8 lg:my-0 bg-glass-dark p-2 hover:border-gray-300 rounded-lg font-all'>
                               <img src={BiThree} alt='' />
                            </div>

                            <div className='block lg:hidden mx-auto'>
                                <iframe className='iframe' src='https://app.powerbi.com/view?r=eyJrIjoiYjUzM2Y3M2YtZTFkOC00NGVkLWI1OWQtNjEwM2Y3MjU5YzcxIiwidCI6IjBmZTk2NWE2LTBhMGYtNDU0Yy05NWY1LWRkOTlkYmE2YmExZSIsImMiOjl9' frameBorder="0" title='last' allowFullScreen={true}>
                                </iframe>
                            </div>

                            <h1 className='mt-4 text-center text-lg font-all font-bold'>Logistics Insights</h1>

                                <p className='font-all text-center text-md lg:text-base leading-loose'>This supply chain disruption app provides insights into your distribution; Geo-location coverage, what causes orders to be delayed or cancelled, Customer satisfaction etc. With multiple filters and views, you can see analyze your logistics KPIs across both Structured and Unstructured data.</p>

                            <div onClick={handleOpen3} className='mt-12 w-1/3 mx-auto font-all rounded-md text-dataorange hover:bg-dataorange hover:text-white p-2 flex flex-row justify-center items-center cursor-pointer'>
                                <FaChartArea />
                                <button className='ml-2'>View</button>
                            </div>

                    </div>
                                
                    </div>




                  
            </div>
            
        </div>
          
        </Fragment>
        </>
    )
}

export default Product
