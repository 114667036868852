import React, {Fragment, useState} from 'react';
import {FaAngleUp} from 'react-icons/fa';
// import { Button } from './Styles';
  
const ScrollButton = () =>{
  // eslint-disable-next-line
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100){
      setVisible(true)
    } 
    else if (scrolled <= 100){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
      <Fragment>
        { document.documentElement.scrollTop > 100 ? (
            
        <button onClick={scrollToTop} className='fixed w-12 cursor-pointer right-10 rounded-md bottom-10 p-2 h-12 flex justify-center items-center z-20 bg-dataorange text-white text-xl'>
     <FaAngleUp size={20} 
      />
    </button>) : ''
  }
  </Fragment>
  );
}
  
export default ScrollButton;