import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Link } from 'react-router-dom'
import BG from '../assets/images/webicons114.png'
import Video from '../assets/video/PowerBI.mp4'
import { FaArrowRight } from "react-icons/fa";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",  backgroundColor: "transparent", color: 'white' , right: '40px'}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
      // alert('koko')
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", backgroundColor: "transparent", color: 'white' , left:'40px', zIndex:'1'}}
        onClick={onClick}
      />
    );
  }

export default class CustomArrows extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      <div className='w-full h-image'>
        <Slider {...settings}>
           <div className='w-full bg-datadark'>
                            <div className='h-page flex flex-col w-full lg:w-11/12 items-center my-auto mx-auto'>

                             <div className='h-full lg:pl-24 mt-24 lg:mb-auto flex flex-col items-center lg:flex-row'>
                                   
                                    <div className='relative h-auto lg:w-2/5 mr-8'>
                                    
                                    <h1 className='uppercase text-2xl md:text-5xl text-center lg:text-left text-datalight font-bold font-oxy'>DATA<span className='text-dataorange'>ZEN</span></h1>
                                    
                                    <div className='mt-12 lg:mt-8 font-oxy text-sm lg:text-2xl text-center lg:text-left text-datalight'>
                                  
                                    TECHNOLOGY DRIVEN INSIGHT FOR MEASURABLE BUSINESS PERFORMANCE
                                    </div>
                                    <div className='mt-12 lg:mt-8 font-all text-md lg:text-base text-center lg:text-left text-datalight'>
                                    Gain Practical Business Intelligence using Data Analytics 
                                    </div>
                                    <div className='mt-12 text-center lg:text-left'>
                                        <Link to='/products' className='px-3 py-2 rounded-md text-datadark hover:text-white bg-dataorange font-all'>Get Started</Link>
                                    </div>

                                    
                            </div>

                            <div className='w-full lg:w-3/5 h-auto my-auto flex justify-center opacity-70'>
                                      <img src={BG} alt='' loading='lazy' className='my-auto w-10/12' />
                                     
                            </div>
                         </div>

                      
                        </div>
                    </div>

                     <div className='w-screen bg-datadark'>
                    <div className='h-page flex flex-col lg:w-11/12 mx-auto items-center my-auto'>
                    
                        <div className='h-full px-12 lg:pl-24 flex flex-col items-center my-auto justify-between lg:flex-row'>
                            <div className='mt-24 lg:mb-auto  h-auto w-full lg:w-2/5 mr-8'>
                                    <h1 className='uppercase text-xl md:text-2xl text-center lg:text-left text-datalight font-bold font-oxy'>Watch Power BI <span className='text-dataorange'>in action</span></h1>
                                    <div className='mt-4 lg:mt-8 font-all text-md lg:text-base text-center lg:text-left text-datalight'>
                                    See how easy it is to create reports, collaborate on dashboards, and share insights—inside and outside your organization—with user-friendly tools for self-service and enterprise BI.
                                    </div>
                                    <div className='mt-8 flex justify-center lg:justify-start text-center lg:text-left'>
                                          <button className='flex flex-row items-center rounded-md text-dataorange font-all'>Play Demo
                                          <FaArrowRight className='ml-2' />
                                          </button>  
                                    </div>
                            </div>

                            <div className='mt-20 lg:mt-0 mb-auto lg:mb-0 w-full lg:w-3/5 h-auto flex justify-center lg:p-20 rounded-lg'>
                                <video controls id="myVideo">
                                        <source src={Video} id='myVid' type="video/mp4"></source>
                                    </video>
                            </div>
                        </div>


                        </div>
                    </div>
 

        </Slider>
        
      </div>
    );
  }
}