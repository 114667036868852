import React, { Fragment } from "react";
import {
    FaFacebookF,
    FaLinkedinIn,
    FaTwitter,
    FaInstagram,
    FaGoogle,
} from "react-icons/fa";

const Footer = () => {
    return (
        <Fragment>
            <footer className="bg-datadark text-gray-600 body-font">
                <div className="container px-5 pt-24 pb-12 mx-auto">
                    <div className="flex flex-wrap md:text-left text-center order-first font-all text-datazen">
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4 ">
                            <div className="my-auto">
                                <div className="mt-0 text-gray-400 text-lg font-all">
                                    &#169; 2021 Data
                                    <span className="text-dataorange">zen</span>
                                </div>
                                <p className="italic text-gray-400 font-all mt-2 text-xs">
                                    All rights reserved{" "}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-bold font-all text-gray-400 tracking-widest text-lg mb-3">
                                Our Solution
                            </h2>
                            <ul className="list-none mb-10">
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Business Intelligence/AI
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Data Management
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Cloud Solutions
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <a
                                        href="//web.talentstudio.app"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Talent Studio
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-bold font-all text-gray-400 tracking-widest text-lg mb-3">
                                Contact Us
                            </h2>
                            <ul className="list-none mb-10">
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Business Intelligence/AI
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Data Management
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <a
                                        href="#!"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        Cloud Solutions
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-bold text-gray-400 tracking-widest text-sm mb-3">
                                Subscribe to our newsletter{" "}
                            </h2>
                            <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
                                <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
                                    <input
                                        type="text"
                                        id="footer-field"
                                        placeholder="Your Email"
                                        name="footer-field"
                                        className="w-full white bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:outline-none text-base outline-none text-gray-300 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                                <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-datadark hover:text-white bg-dataorange border-0 py-2 px-6 focus:outline-none rounded">
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-11/12 lg:w-5/6 px-12 lg:mt-4 mx-auto text-left py-8 text-md font-all text-gray-400 flex flex-col-reverse sm:flex-row justify-between">
                    <div></div>

                    <div className="flex flex-row my-auto">
                        <FaFacebookF className="text-2xl text-gray-400" />
                        <FaLinkedinIn className="text-2xl text-gray-400 ml-4" />
                        <FaTwitter className="text-2xl text-gray-400 ml-4" />
                        <FaInstagram className="text-2xl text-gray-400 ml-4" />
                        <FaGoogle className="text-2xl text-gray-400 ml-4" />
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
