import React, { Fragment } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Icon from "../../assets/images/Icon-min.png";
import { AiOutlineAlignRight, AiOutlineClose } from "react-icons/ai";
import talentStudio from "./../../assets/images/talent-studio.png";
import AI from "./../../assets/images/analytics.png";
import IA from "./../../assets/images/services.png";

const Header = () => {
    const openNav = () => {
        if (document.getElementById("myNav")) {
            document.getElementById("myNav").style.height = "100%";
        }
    };

    const closeNav = () => {
        if (document.getElementById("myNav")) {
            document.getElementById("myNav").style.height = "0%";
        }
    };

    return (
        <Fragment>
            <div className="h-scale w-full p-4 bg-datazen font-all flex justify-center bg-datadark">
                <div className="h-scale w-11/12 lg:w-full lg:px-20 flex flex-row content-center mt-auto mb-auto">
                    <Link to="/" className="my-auto">
                        <img src={Icon} alt="" className="w-8 h-8" />
                    </Link>

                    <div className="ml-auto my-auto hidden md:block">
                        <ul className="flex flex-row justify-between mt-auto mb-auto">
                            <li className="mr-8 mt-auto mb-auto">
                                <div className="bg-transparent">
                                    <Link
                                        to="about"
                                        className="outline-none text-stuff focus:outline-none rounded-sm flex items-center mt-auto mb-auto"
                                    >
                                        <span className="pr-1 text-xs lg:text-sm">
                                            About Us
                                        </span>
                                    </Link>
                                </div>
                            </li>

                            <div className="mr-6 group md:inline-block bg-transparent">
                                <div className="outline-none text-stuff focus:outline-none rounded-sm flex items-center mt-auto mb-auto">
                                    <span className="pr-1 text-xs lg:text-sm">
                                        Products & Services
                                    </span>
                                    <span>
                                        <svg
                                            className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                    transition duration-150 ease-in-out"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </span>
                                </div>

                                <ul className="-translate-x-1/4 inline-block mx-auto mt-4 bg-gray-100 border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top w-76 lg:w-96 shadow-xl py-4 px-2 text-sm">
                                    <a
                                        href={"//web.talentstudio.app"}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="my-4 rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-700 hover:text-gray-400 flex"
                                    >
                                        <div className="rounded-md mr-8 flex items-center">
                                            <img
                                                src={talentStudio}
                                                alt="Talent Studio"
                                                style={{ width: "4em" }}
                                            />
                                        </div>
                                        <div className="h-auto flex flex-col content-center">
                                            <div className="text-md font-bold my-auto">
                                                Talent Studio
                                            </div>
                                            <p className="text-xs my-auto">
                                                {" "}
                                                Recruitment is hectic. Try out
                                                our automated Talent Recruitment
                                                & Management software{" "}
                                                <span className="italic">
                                                    today!
                                                </span>
                                            </p>
                                        </div>
                                    </a>

                                    <Link
                                        to="/products"
                                        rel="noreferrer"
                                        className="my-4 rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-700 hover:text-gray-400 flex"
                                    >
                                        <div className="rounded-md mr-6 flex items-center">
                                            <img
                                                src={AI}
                                                alt=""
                                                style={{ width: "3.5em" }}
                                            />
                                        </div>
                                        <div className="h-auto flex flex-col content-center">
                                            <div className="text-md font-bold my-auto">
                                                Analytics Apps
                                            </div>
                                            <p className="text-xs my-auto">
                                                AI powered Business Intelligence
                                                apps to help improve decision
                                                making
                                            </p>
                                        </div>
                                    </Link>

                                    <Link
                                        to="/services"
                                        rel="noreferrer"
                                        className="my-4 rounded-sm px-3 py-1 hover:bg-gray-100 text-gray-700 hover:text-gray-400 flex"
                                    >
                                        <div className="p-0 rounded-md mr-6 flex items-center">
                                            <img
                                                src={IA}
                                                alt=""
                                                style={{ width: "2.5em" }}
                                                className="bg-white"
                                            />
                                        </div>
                                        <div className="h-auto flex flex-col content-center">
                                            <div className="text-md font-bold my-auto">
                                                Our Services
                                            </div>
                                            <p className="text-xs my-auto">
                                                Find out more on our service
                                                offerings
                                            </p>
                                        </div>
                                    </Link>
                                </ul>
                            </div>
                            <Link
                                to="/learn"
                                className="mr-8 mt-auto mb-auto text-xs lg:text-sm text-stuff"
                            >
                                Learn
                            </Link>
                            <Link
                                to="/contact"
                                className="mr-8 mt-auto mb-auto text-xs lg:text-sm text-stuff"
                            >
                                Contact
                            </Link>
                        </ul>
                    </div>
                    <button
                        className="my-auto content-center md:hidden ml-auto"
                        onClick={() => openNav()}
                    >
                        <AiOutlineAlignRight className="text-white text-2xl" />
                    </button>
                </div>
            </div>
            {/* <Bounce top when={nav}> */}
            <div id="myNav" className="overlay">
                <button className="close-btn" onClick={() => closeNav()}>
                    <AiOutlineClose className="text-white text-2xl" />
                </button>
                <div className="overlay-content flex flex-col font-all">
                    <Link
                        to="/about"
                        className="text-stuff my-4"
                        onClick={() => closeNav()}
                    >
                        About Us
                    </Link>
                    <Link
                        to="/products"
                        className="text-stuff my-4"
                        onClick={() => closeNav()}
                    >
                        Analytics Apps
                    </Link>
                    <Link
                        to="/services"
                        className="text-stuff my-4"
                        onClick={() => closeNav()}
                    >
                        Our Services
                    </Link>
                    <Link
                        to="/learn"
                        className="text-stuff my-4"
                        onClick={() => closeNav()}
                    >
                        Learn
                    </Link>
                    <Link
                        to="/contact"
                        className="text-stuff my-4"
                        onClick={() => closeNav()}
                    >
                        Contact
                    </Link>
                </div>
            </div>
            {/* </Bounce> */}
        </Fragment>
    );
};

export default Header;
