import React,{Fragment} from 'react'
import ABOUT from '../../assets/images/webicons01.png'
import strength from '../../assets/images/strenght.svg'
import vision from '../../assets/images/vission.svg'
import mission from '../../assets/images/mission.svg'
import Micro from '../../assets/images/micro.png'
import Oracle from '../../assets/images/oracle.png'
import HP from '../../assets/images/HPLogo.png'
import TDD from '../../assets/images/tdd.jpeg'
import Prune from '../../assets/images/prunedge.png'
import { FaArrowRight,FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <Fragment>
        <div className='w-full bg-datadark'>
                    <div className='h-height flex flex-col w-full items-center lg:w-11/12 mx-auto my-auto'>
                    
                        <div className='h-full pl-12 lg:pl-24 flex lg:flex-row flex-col justify-between my-auto'>
                            <div className='mt-16 xl:my-auto h-auto w-11/12 lg:w-1/2 mr-8'>
                                    <h1 className='uppercase text-xl lg:text-2xl text-datalight font-bold font-oxy'>KNOW <span className='text-dataorange'>ABOUT US</span></h1>
                                    <div className='mt-4 lg:mt-8 font-all text-md lg:text-sm text-left text-datalight'>
                                    Datazen is a tech solution company with expertise in software & data solutions – and we focus on supporting a modern culture of digital transformation. We offer business software products across Data Management, Analytics (Business Intelligence) and Human capital.
                                    <br></br>
                                    <br></br>
                                    <br></br>
Data with us is an end-to-end strategy to empower you in building a data culture for business productivity, success and staying informed. We employ our high standards, first class innovation, and top-quality personnel to deliver Business Technology solutions that drive the operations of organizations for growth and effectiveness
                                    </div>
                                    <div className='mt-4 lg:mt-12'>
                                    <a href='#about' className='px-3 py-2 rounded-lg text-datadark hover:text-white bg-dataorange font-all'>Learn More
                                    </a>
                                    </div>
                            </div>


                            <div className='block lg:w-1/2 my-auto h-auto flex items-center opacity-80'>
                                    <img src={ABOUT} loading='lazy' alt='' className='my-auto w-10/12' />
                            </div>
                        </div>

                        </div>

                    </div>
                    
                   

                    
        <div className='px-8 lg:px-0 pb-12 lg:pb-12 pattern'>
                  
        <div id='about' className='mt-12 w-full text-2xl lg:text-3xl text-datadark font-ball font-bold text-center lg:w-4/6 mx-auto'>
                            Our Team
                        </div>

            <div className='mt-12 w-full text-md lg:text-base text-datadark leading-loose font-all text-center lg:w-4/6 mx-auto'>
            
            <p className='text-center'>A group of bright and dedicated problem-solvers that bring their best each time to build great technology solutions.</p> 
            {/* <br></br> */}

We maintain an end-to-end outlook when delivering technology that supports your Business productivity, Data Insights - as well as your ability to leverage the power of technology and our products to grow your Organizations.
<br></br>
 We employ high standards, relentless innovation and top-quality R&D to deliver value and topnotch outputs.
            </div>



            <div className='mt-20 w-full lg:w-5/6 mx-auto text-datadark sm:grid sm:grid-cols-3 sm:gap-8'>
                            <div className='my-8 sm:my-0 bg-blue-50 hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-20 rounded-xl mission p-4'>
                                    <img src={mission} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Mission
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                To Empower individuals in the modern workplace with tools for business productivity and intelligent business insights.
                                </p>
                            </div>

                            <div className='my-8 sm:my-0 bg-blue-50 hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-20 rounded-xl vision p-4'>
                                      <img src={vision} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Vision
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                Be a Leading Provider of Business Intelligence (BI/AI) software products from Sub-Saharan Africa
                                </p>
                            </div>

                            <div className='my-8 sm:my-0 bg-blue-50 hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='w-20 rounded-xl strength p-4'>
                                    <img src={strength} alt='' className='' loading='lazy' />
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8'>
                                    Our Strength
                                </h1>
                                <p className='mt-8 text-md lg:text-base'>
                                    Fierce desire to continually buid IT solutions that meet the challenges of the ever-changing modern world and marketplace.
                                </p>
                            </div>
                          
                    </div>


                    <div className='mt-20 w-full text-sm text-datadark text-md lg:text-base leading-loose font-all text-center lg:w-4/6 mx-auto'>
                        {/* We employ customer-centred mindsets to technology building and very intimate in our customer support, We are a fast-growing tech company out of Lagos, Nigeria */}

                        <span className='flex flex-row justify-center'>
                        <FaArrowRight className='mt-1 mr-2 text-dataorange'/>
                        We maintain strategic industry partnerships world-class OEM organizations
                        <FaArrowLeft className='mt-1 ml-2 text-dataorange' />
                        </span>
                        <span className='flex flex-row justify-center'>
                        <FaArrowRight className='mt-1 mr-2 text-dataorange'/>
                        We offers quality products and services for amazing affordable rates.
                        <FaArrowLeft className='mt-1 ml-2 text-dataorange' />
                        </span>
                        <span className='flex flex-row justify-center'>
                        <FaArrowRight className='mt-1 mr-2 text-dataorange'/>
                        Data with us is an end-to-end strategy; it is the new fuel for business innovation so we incorporate a data-centered model in our solutions.
                        <FaArrowLeft className='mt-1 ml-2 text-dataorange'/>
                        </span>
                    </div>

            
        </div>
        <div className='max-w-full overflow-x-hidden bg-all text-center justify-center lg:justify-start lg:pl-12 lg:text-left flex items-center'>

                <div className=''>
                        <div className='font-oxy text-md lg:text-4xl font-bold text-white'>
                            Begin your journey with us today
                        </div>
                        <Link to='/contact'><button className='mt-8 px-3 py-2 rounded-md hover:text-white text-datadark bg-dataorange font-all'>Get Started</button></Link>
                </div>

                
            </div>

            <h2 className='mt-12 my-12 text-center font-ball text-xl font-bold text-datadark'>Meet Our Partners</h2>
                            <div className='pb-12 w-2/3 flex flex-col justify-center lg:flex-row lg:justify-between mx-auto align-center'>
                                <p className='mx-auto my-8 lg:my-0'>
                                    <img src={Micro} alt='' className='w-32' />
                                </p>
                               
                                <p className='bg-transparent p-2 rounded-lg mx-auto my-8 lg:my-0'>
                                    <img src={Oracle} alt='' className='w-32' />
                                </p>
                                <p className='mx-auto my-8 lg:my-0'>
                                    <img src={HP} alt='' className='w-12'/>
                                </p>
                                <p className='mx-auto my-8 lg:my-0'>
                                    <img src={TDD} alt='' className='w-16'/>
                                </p>
                                <p className='mx-auto my-8 lg:my-0'>
                                    <img src={Prune} alt='' className='w-32'/>
                                </p>
                                
                            </div>
        </Fragment>
    )
}

export default About
