import React,{ Fragment, useState, useRef } from 'react'
import Datazen from '../../assets/images/Datazen-min.png'
import { MdEmail,MdPhone, MdLocationOn } from 'react-icons/md'
import CONTACT from '../../assets/images/webicons08.png'
import {FaLinkedinIn,FaTwitter,FaInstagram,FaGoogle } from 'react-icons/fa'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd'
import emailjs from 'emailjs-com'
import { EmailDetails } from '../../components/sendEmail'

const Contact = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({
        name: '',
        email: '',
        subject:'',
        message:'',
     })

     const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery((prevState) => ({
          ...prevState,
          [name]: value
        }));
      };

      const submitFormData = (e) => {
        setLoading(true)
        e.preventDefault()
        if(query.name === '' || query.email === '' || query.subject === '' || query.message === '') {
            setLoading(false)
            toast.error('Please, fill all fields before proceeding to submit!!!',{
                autoClose: 2000,
                hideProgressBar: true
              })
        }
        else
        {
                
                emailjs.sendForm(EmailDetails.SERVICE_ID, EmailDetails.TEMPLATE_ID, form.current, EmailDetails.USER_ID)
                .then((result) => {
                    toast.success(`${result.text}, Message sent successfully. We'll get back to you shortly`,{
                        autoClose: 2000,
                        hideProgressBar: true
                      })
                    setLoading(false)
                      setQuery({
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    })
                }, (error) => {
                    toast.error(`${error.text}, Please try again!`,{
                        autoClose: 2000,
                        hideProgressBar: true
                      })
                    setLoading(false)
                });
        }
        
       }

    return (
        <Fragment>
         <div className='w-full bg-datadark'>
                    <div className='h-height flex flex-col lg:flex-row w-full justify-center lg:justify-between items-center lg:w-8/12 mx-auto my-auto'>

                        <div className='lg:pl-24 uppercase text-xl lg:text-2xl text-datalight font-bold font-oxy'>Reach Out <span className='text-dataorange'>to Us</span></div>

                        <div className='ml-auto opacity-60 w-full lg:w-4/12'>
                             <img src={CONTACT} loading='lazy' alt='' className='w-full my-auto' />
                        </div>

                        </div>
                    </div>

                 <ToastContainer />
                 <div className='px-8 lg:px-0 py-4 lg:py-8 pattern'>

                            <div className='mt-4 w-full text-sm lg:text-base text-datadark leading-loose font-all text-center lg:w-4/6 mx-auto'>
                            
                            Got a project you would like us to work together on?

                            </div>


                <div className='mt-0 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:gap-12'>

                            <div className='mt-4 lg:mt-16 flex h-auto flex-row justify-center'>
                                <form ref={form}  className='w-full h-full lg:w-3/4 bg-datadark font-all text-xs p-8 rounded-lg'>
                                    <h3 className='font-all text-center text-xl text-white font-bold'>Contact us</h3>
                                    <div className='mt-4 grid grid-cols-2 gap-4'>
                                        <div className='flex flex-col'>
                                            <input type='text' placeholder='Your Name' name='name' value={query.name} onChange={e => onChange(e)} className='mt-2 border border-gray-200 p-2 py-2 rounded-md outline-none' />
                                        </div>
                                        <div className='flex flex-col'>
                                            <input type='text' placeholder='Your Email' name='email' value={query.email} onChange={e => onChange(e)} className='mt-2 border border-gray-200 p-2 py-2 rounded-md outline-none' />
                                        </div>
                                    </div>

                                    
                                    <div className='mt-4 flex flex-col'>
                                        <input type='text' placeholder='Your Subject' name='subject' value={query.subject} onChange={e => onChange(e)} className='mt-2 border border-gray-200 p-2 py-2 rounded-md outline-none' />
                                    </div>
                                    <div className='mt-4 flex flex-col'>
                                        <textarea type='text' placeholder='Your Message' name='message' value={query.message} onChange={e => onChange(e)} className='h-40 mt-2 border border-gray-200 p-2 py-2 rounded-md outline-none' ></textarea>
                                    </div>
                                    <div className='mt-8 flex justify-center'>
                                        <Button loading={loading} danger type='primary' onClick={(e)=>submitFormData(e)} className='rounded-lg text-datadark hover:text-white bg-dataorange font-all'>Send Message
                                        </Button>
                                    </div>
                                </form>
                            </div>

                            <div className='mt-4 lg:mt-auto lg:mb-auto flex flex-col px-8'>

                                    <div className='font-all text-datadark text-lg'> 
                                             <div>
                                                <img src={Datazen} alt='' className='w-32 lg:w-48' />
                                            </div>

                                            <div className='mt-8 flex flex-row '>
                                                    <span className='my-auto'>
                                                        <MdLocationOn className='text-lg mr-4'/>
                                                    </span>
                                                    1625b Saka Jojo St, Victoria Island 106104, Lagos</div>

                                            <div className='mt-4 flex flex-row text-datadark'>
                                                 <span className='my-auto mr-4'>
                                                        <MdPhone />
                                                    </span>
                                                +234 903-774-8960
                                            </div>
                                            <div className='mt-4 flex flex-row text-datadark'>
                                                    <span className='my-auto mr-4' >
                                                        <MdEmail />
                                                    </span>
                                                        info@datazen.online
                                            </div>

                                            <div className='my-8 grid grid-cols-4 gap-4 text-datadark justify-between'>
                                                    <a href='https://www.linkedin.com/company/datazen-limited/?originalSubdomain=ng' target='_blank' rel="noreferrer" className='w-full border bg-white border-gray-100 hover:bg-datadark text-datadark  hover:text-blue-50 rounded-lg p-4'>
                                                        <FaLinkedinIn className='mx-auto'/>
                                                    </a>
                                                    <a href='https://twitter.com/datazen.online' target='_blank' rel="noreferrer" className='w-full border bg-white border-gray-100 hover:bg-datadark text-datadark  hover:text-blue-50 rounded-lg p-4'>
                                                        <FaTwitter className='mx-auto'/>
                                                    </a>
                                                    <a href='https://instagram.com/Datazen2' target='_blank' rel="noreferrer" className='w-full border bg-white border-gray-100 hover:bg-datadark text-datadark  hover:text-blue-50 rounded-lg p-4'>
                                                        <FaInstagram className='mx-auto'/>
                                                    </a>
                                                    <a href='https://www.google.com/search?q=datazen+limited&sxsrf=AOaemvJzr2wK0302JTSlZdB1D1f_zuMyIg%3A1632408460867&ei=jJNMYeLANPuEjLsP_Z-wkAk&gs_ssp=eJzj4tVP1zc0zDUxyzY0ys41YLRSNagwNDBOskgyMU9ONTM3MDC3tAIKpaSkmSYnGSZZGJqkJJsne_GnJJYkVqXmKeRk5maWpKYAALifFQg&oq=datazen+l&gs_lcp=Cgdnd3Mtd2l6EAMYATIECCMQJzIKCC4QxwEQrwEQJzIICAAQFhAKEB4yBggAEBYQHjICCCYyAggmOgcIABCwAxAeOgkIABCwAxAIEB5KBAhBGAFQxRNY7Bxg1DFoAXAAeACAAfgBiAHTBpIBBTAuMS4zmAEAoAEByAECwAEB&sclient=gws-wiz'
                                                    target='_blank' rel="noreferrer"
                                                    className='w-full border bg-white border-gray-100 hover:bg-datadark text-datadark  hover:text-blue-50 rounded-lg p-4'>
                                                        <FaGoogle className='mx-auto'/>
                                                    </a>
                                                    </div>

                                    
                                    </div>

                                   
                            </div>


                            </div>
                            
                            <div className='mt-8 lg:grid lg:grid-cols-2 lg:gap-8'>
                               
                                <div></div>

                                <div className='w-full p-8'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3964.7371523202!2d3.4145414!3d6.4278054!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8acdfd6513a7%3A0x65d116707a03e556!2s1625b%20Saka%20Jojo%20St%2C%20Victoria%20Island%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1629974019490!5m2!1sen!2sng" title='Map' className='maps h-60 w-full md:h-96 rounded-xl border-none' allowFullScreen={false} loading="lazy"></iframe>
                                    </div>

                            </div>
                    
                    </div>         

            
        </Fragment>
    )
}

export default Contact
