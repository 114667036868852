import React, { Fragment } from 'react'
import Image from '../../assets/images/webicons10.png'

const Learn = () => {
    return (
        <Fragment>
            <div className='w-full bg-datadark'>
                    <div className='h-height flex flex-col w-full items-center lg:w-11/12 mx-auto my-auto'>
                    
                        <div className='h-full pl-12 lg:pl-24 flex lg:flex-row flex-col justify-between my-auto'>
                            <div className='mt-16 xl:my-auto h-auto w-11/12 lg:w-1/2 mr-8'>
                            <h1 className='uppercase text-xl lg:text-2xl text-datalight font-bold font-oxy'>LEARN RELEVANT SKILLS <span className='text-dataorange'>FOR FREE</span></h1> 
                                    <div className='mt-4 lg:mt-8 font-all text-md lg:text-base text-left text-datalight'>
                                    The COVID-19 pandemic has negatively affected jobs around the world like never before. In many countries, millions of people have lost their jobs and means of livelihood. Microsoft has identified 10 digital skill areas that have more than 32 million job openings in 2020 and will have 149 million new jobs by 2025. In a bid to skill up millions around the world, Microsoft is offering free courses in these skill areas for everyone, with the opportunity to get certified at a flat rate of $15 through its partners.
            Datazen is presenting a platform for access to the learning resources and certification opportunity in three simple steps.
                        
                                    </div> 
                                    <div className='mt-4 lg:mt-12'>
                                        <a href='#learn' className='px-3 py-2 rounded-lg text-datadark hover:text-white bg-dataorange font-all'>Learn More</a>
                                    </div>
                            </div>


                            <div className='block lg:w-1/2 my-auto h-auto flex items-center opacity-80'>
                                    <img src={Image} loading='lazy' alt='' className='my-auto w-10/12' />
                            </div>
                        </div>

                        </div>

                    </div>



                    <div className='px-8 lg:px-0 py-12 lg:py-24 pattern'>
                        

                        <div className='mt-12 w-full text-sm leading-loose font-all text-center lg:w-4/6 mx-auto'>
                        
                 
                        </div>

                        <div id='learn' className='mt-8 lg:mt-12 w-full text-2xl lg:text-3xl font-ball font-bold text-center lg:w-4/6 mx-auto'>
                        How to Participate
                        </div>


                         <div className='mt-20 w-full lg:w-5/6 mx-auto lg:grid lg:grid-cols-3 lg:gap-8'>
                        
                            <div className='my-8 lg:my-0 bg-blue-50 border-b-4 border-dataorange hover:border-gray-300 rounded-2xl p-8 font-all'>
                                <div className='p-4 text-xl lg:text-4xl font-bold text-center text-dataorange'>
                                    STEP 1
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8 text-center'>Pick a Skill Track</h1>
                                <p className='mt-8 text-md lg:text-base'>Datazen Learn is offering 6 tracks. Software Developer, IT Administrator, Customer Service Representative, IT Support, Data Analyst and Sales Representative.</p>
                            </div>

                            <div className='my-8 lg:my-0 bg-blue-50 border-b-4 border-dataorange hover:border-gray-300 rounded-2xl p-8 font-all'>
                               <div className='p-4 text-xl lg:text-4xl font-bold text-center text-dataorange'>
                                    STEP 2
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8 text-center'>Complete the course</h1>
                                <p className='mt-8 text-md lg:text-base'>Complete the video course through the Datazen Learn platform in any of the 6 tracks to be eligible for certification. For technical tracks, you will need to cover additional in-depth technical learning content on the Microsoft Learn platform before certification.</p>
                            </div>

                            <div className='my-8 lg:my-0 bg-blue-50 border-b-4 border-dataorange hover:border-gray-300 rounded-2xl p-8 font-all'>
                                    <div className='p-4 text-xl lg:text-4xl font-bold text-center text-dataorange'>
                                    STEP 3
                                </div>
                                <h1 className='lg:text-xl font-bold mt-8 text-center'>Get certified</h1>
                                <p className='mt-8 text-md lg:text-base'>After completing the course, you quailfy to take the associated certification examination at a flat rate of $15.</p>
                            </div>
                          
                    </div>

                    <div className='mt-12 text-center'>
                                        <a href='https://www.datazen.online/learn#' target="_blank" rel="noreferrer" className='px-4 py-3 rounded-lg text-white bg-dataorange font-all'>Get Started</a>
                    </div>








             
        </div>
        </Fragment>
    )
}

export default Learn


